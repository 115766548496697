var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navbar-bookmarks flex items-center" }, [
    _c(
      "ul",
      { staticClass: "vx-navbar__starred-pages" },
      [
        _c(
          "draggable",
          {
            staticClass: "flex cursor-move",
            attrs: { group: { name: "pinList" } },
            model: {
              value: _vm.starredPagesLimited,
              callback: function ($$v) {
                _vm.starredPagesLimited = $$v
              },
              expression: "starredPagesLimited",
            },
          },
          _vm._l(_vm.starredPagesLimited, function (page) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: page.rule,
                    expression: "page.rule",
                  },
                ],
                key: page.url,
                staticClass: "starred-page",
              },
              [
                _c("feather-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.bottom",
                      value: {
                        content: page.title,
                        delay: { show: 1000, hide: 100 },
                      },
                      expression:
                        "{\n            content: page.title,\n            delay: { show: 1000, hide: 100 },\n          }",
                      modifiers: { bottom: true },
                    },
                  ],
                  staticClass: "p-2 cursor-pointer",
                  attrs: {
                    svgClasses: ["h-6 w-6 stroke-current", _vm.textColor],
                    icon: page.icon,
                  },
                  on: {
                    click: function ($event) {
                      _vm.$router.push(page.url).catch(() => {})
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm.starredPagesMore.length
      ? _c(
          "div",
          { staticClass: "vx-navbar__starred-pages--more-dropdown" },
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
              },
              [
                _c("feather-icon", {
                  staticClass: "cursor-pointer p-2",
                  attrs: { icon: "ChevronDownIcon", svgClasses: "h-4 w-4" },
                }),
                _c("vs-dropdown-menu", [
                  _c(
                    "ul",
                    { staticClass: "vx-navbar__starred-pages-more--list" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "cursor-move",
                          attrs: { group: { name: "pinList" } },
                          model: {
                            value: _vm.starredPagesMore,
                            callback: function ($$v) {
                              _vm.starredPagesMore = $$v
                            },
                            expression: "starredPagesMore",
                          },
                        },
                        _vm._l(_vm.starredPagesMore, function (page) {
                          return _c(
                            "li",
                            {
                              key: page.url,
                              staticClass:
                                "starred-page--more flex items-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  _vm.$router.push(page.url).catch(() => {})
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "ml-2 mr-1",
                                attrs: {
                                  icon: page.icon,
                                  svgClasses: [
                                    "h-5 w-5 stroke-current",
                                    _vm.textColor,
                                  ],
                                },
                              }),
                              _c("span", { staticClass: "px-2 pt-2 pb-1" }, [
                                _vm._v(_vm._s(page.title)),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "bookmark-container" },
      [
        _c("feather-icon", {
          staticClass: "cursor-pointer p-2",
          attrs: {
            icon: "StarIcon",
            svgClasses: ["stoke-current text-warning", _vm.textColor],
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.showBookmarkPagesDropdown = !_vm.showBookmarkPagesDropdown
            },
          },
        }),
        _vm.showBookmarkPagesDropdown
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.outside,
                    expression: "outside",
                  },
                ],
                staticClass: "absolute bookmark-list w-1/3 xl:w-1/4 mt-4",
              },
              [
                _c("vx-auto-suggest", {
                  ref: "bookmarkAutoSuggest",
                  attrs: {
                    autoFocus: true,
                    data: _vm.navbarSearchAndPinList,
                    initalData: {
                      pages: _vm.starredPagesLimited.concat(
                        _vm.starredPagesMore
                      ),
                    },
                    searchLimit: 5,
                    placeholder: `${_vm.$t("busca")}...`,
                    inputClassses: "w-full",
                    "show-action": "",
                    "show-pinned": "",
                    hideGroupTitle: "",
                    "background-overlay": "",
                  },
                  on: {
                    input: _vm.hnd_search_query_update,
                    selected: _vm.selected,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "pages",
                        fn: function ({ suggestion }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-end leading-none py-1",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-4",
                                      attrs: {
                                        icon: suggestion.icon,
                                        svgClasses: "h-5 w-5",
                                      },
                                    }),
                                    _c("span", { staticClass: "mt-1" }, [
                                      _vm._v(_vm._s(suggestion.title)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "StarIcon",
                                    svgClasses: [
                                      {
                                        "text-warning":
                                          suggestion.is_bookmarked,
                                      },
                                      "h-5 w-5 stroke-current mt-1",
                                    ],
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.actionClicked(suggestion)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3002600240
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }