var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex mr-4" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullSearch,
              expression: "showFullSearch",
            },
          ],
          staticClass:
            "search-full-container w-full h-full absolute left-0 top-0",
          class: { flex: _vm.showFullSearch },
        },
        [
          _c("vx-auto-suggest", {
            ref: "navbarSearch",
            staticClass: "w-full",
            attrs: {
              autoFocus: _vm.showFullSearch,
              data: _vm.navbarSearchAndPinList,
              search_key: "title",
              "background-overlay": "",
              inputClassses:
                "w-full vs-input-no-border vs-input-no-shdow-focus",
              icon: "SearchIcon",
              placeholder: `${_vm.$t("busca")}...`,
            },
            on: {
              input: _vm.hnd_search_query_update,
              selected: _vm.selected,
              closeSearchbar: function ($event) {
                _vm.showFullSearch = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "group",
                fn: function ({ group_name }) {
                  return [
                    _c("p", { staticClass: "font-semibold text-primary" }, [
                      _vm._v(_vm._s(_vm.$t(group_name || _vm.title))),
                    ]),
                  ]
                },
              },
              {
                key: "pages",
                fn: function ({ suggestion }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-end leading-none py-1" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-4",
                          attrs: {
                            icon: suggestion.icon,
                            svgClasses: "h-5 w-5",
                          },
                        }),
                        _c("span", { staticClass: "mt-1" }, [
                          _vm._v(_vm._s(suggestion.title)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "files",
                fn: function ({ suggestion }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-between" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "div",
                            { staticClass: "img-container w-8 mr-3 flex" },
                            [
                              _c("img", {
                                staticClass: "responsive",
                                attrs: {
                                  src: require(`@/assets/images/file-icons/${suggestion.file_ext}.png`),
                                  alt: suggestion.file_name,
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "leading-none mt-1" }, [
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(_vm._s(suggestion.file_name)),
                            ]),
                            _c("small", [
                              _vm._v("by " + _vm._s(suggestion.from)),
                            ]),
                          ]),
                        ]),
                        _c("small", [_vm._v(_vm._s(suggestion.size))]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "contacts",
                fn: function ({ suggestion }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-between" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c("img", {
                            staticClass: "w-8 h-8 mr-3 rounded-full",
                            attrs: {
                              src: suggestion.img,
                              alt: suggestion.name,
                            },
                          }),
                          _c("div", { staticClass: "leading-none mt-1" }, [
                            _c("p", [_vm._v(_vm._s(suggestion.name))]),
                            _c("small", [_vm._v(_vm._s(suggestion.email))]),
                          ]),
                        ]),
                        _c("small", [_vm._v(_vm._s(suggestion.time))]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "noResult",
                fn: function ({ group_name }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-4",
                          attrs: { icon: "InfoIcon", svgClasses: "h-5 w-5" },
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("nenhum-resultado-encontrado"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "absolute right-0 h-full z-50" },
            [
              _c("feather-icon", {
                staticClass: "px-4 cursor-pointer h-full close-search-icon",
                attrs: { icon: "XIcon" },
                on: {
                  click: function ($event) {
                    _vm.showFullSearch = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("feather-icon", {
        staticClass: "cursor-pointer navbar-fuzzy-search ml-4",
        attrs: { icon: "SearchIcon" },
        on: {
          click: function ($event) {
            _vm.showFullSearch = true
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }