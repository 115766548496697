var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: { title: _vm.$t("grupo"), active: _vm.visible },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "w-full text-center mb-4" }, [
          _vm._v(
            _vm._s(
              _vm.$t("selecione-o-grupo-para-ter-acesso-as-funcionalidades")
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "grid grid-cols-2 grid-rows-1 gap-6" }, [
        _c(
          "div",
          {
            class: _vm.personaContainerClasses,
            on: {
              mouseover: function ($event) {
                _vm.hover = "student"
              },
              mouseleave: function ($event) {
                _vm.hover = "none"
              },
              click: function ($event) {
                return _vm.selectedRole("student")
              },
            },
          },
          [
            _c("div", { class: _vm.selectedBackground("student") }, [
              _c("div", { class: _vm.selectedBorder("student") }, [
                _c("img", {
                  class: _vm.personaImgClasses,
                  style: _vm.personaImgStyle,
                  attrs: {
                    src: require("@/assets/images/personas/student.png"),
                  },
                }),
              ]),
            ]),
            _c("label", { class: _vm.selectedLabel("student") }, [
              _vm._v(" ALUNO "),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: _vm.personaContainerClasses,
            on: {
              mouseover: function ($event) {
                _vm.hover = "teacher"
              },
              mouseleave: function ($event) {
                _vm.hover = "none"
              },
              click: function ($event) {
                return _vm.selectedRole("teacher")
              },
            },
          },
          [
            _c("div", { class: _vm.selectedBackground("teacher") }, [
              _c("div", { class: _vm.selectedBorder("teacher") }, [
                _c("img", {
                  class: _vm.personaImgClasses,
                  style: _vm.personaImgStyle,
                  attrs: {
                    src: require("@/assets/images/personas/teacher.png"),
                  },
                }),
              ]),
            ]),
            _c("label", { class: _vm.selectedLabel("teacher") }, [
              _vm._v(" PROFESSOR "),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }